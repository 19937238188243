:root {
  --gray: #272727;
  --white: #FFFFFF;
  --red: #d42e32;
  --orange: rgb(255, 150, 38)
}

* {
  font-family: 'Montserrat', sans-serif;
  color: var(--gray);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  z-index: 9999;
  position: sticky;
  top: 0;
  background-color: #FFF;
  box-shadow: 1px 1px 5px 1px #333;
}

.hamburger-close, .hamburger-open {
  display: none;
}

.label {
  padding: 0.5rem 1rem;
  text-align: center;
  width: 100%;
  background-color: var(--gray);
}
.label span, .label span i {
  margin-left: 1rem;
  font-weight: 600;
  color: var(--white);
}

.nav-header {
  display: flex;
  align-items: center;
}

.nav-menu {
  padding: 0 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  list-style-type: none;
  justify-content: space-evenly;
}

.menu-main a{
  font-size: 1.05rem;
  text-transform: capitalize;
  font-weight: 500;
  text-decoration: none;
  color: var(--gray);
  transition: all ease-in-out 200ms;
}

.selected a{
  color: var(--red);
  font-weight: 600;
  font-size: 1.25rem;
  text-decoration: underline;
}

.menu-main:hover a {
  font-weight: 700;
  font-size: 1.25rem;
}

.menu-main:hover a + .sub-menu{
  font-weight: 600;
  display: block;
}

.sub-menu {
  position: absolute;
  display: none;
  list-style-type: none;
  z-index: 100;
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.menu {
  padding: 0.5rem 1rem; 
  transition: all ease-in-out 200ms;
}

.menu:hover {
  color: var(--white);
  background-color: var(--gray);
  cursor: pointer;
}

.down::after {
  content: "+";
  margin-left: 0.25rem;
}

.menu-main:hover .down::after {
  content: "-";
  margin-left: 0.25rem;
  font-size: 1.15rem;
  font-weight: 700;
}

.menu:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.menu:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.footer {
  background-color: var(--gray);
  padding: 4rem;
}

.upper-column-footer {
  display: flex;
  justify-content: space-between;
}

.about-us h3, .school-map h3 {
  color: var(--white);
  padding: 0 0 2rem 0;
}

.about-us p {
  color: #c7c7c7;
  line-height: 2rem;
}

.contact-us {
  display: flex;
  margin-top: 1rem;
}

.email, .call-us, .google-direction {
  flex-grow: 1;
  display: flex;
  padding: 1rem 0;
  background-color: #333333;
  align-items: center;
}

.email i, .call-us i, .google-direction i {
  font-size: 2rem;
  color: var(--white);
  background-color: var(--red);
  padding: 1rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.email p, .call-us p, .google-direction p {
  color: #c7c7c7;
  margin-right: 1rem;
}

.email a, .call-us a, .google-direction a {
  color: var(--white);
  margin-right: 1rem;
}

.about-us {
    width: 20%;
  }

@media screen and (max-width: 1024px) {

  .label {
  padding: 0.25rem;
}
.label span, .label span i {
  margin-left: 0.5rem;
  font-weight: 400;
}

.nav-header {
  flex-direction: column;
}

.nav-menu {
  padding: 1rem 0;
  background-color: var(--gray);
}

.menu-main a{
  color: var(--white);
  font-size: 1rem;
  font-weight: 400;
}

.selected a{
  color: var(--red);
  font-weight: 400;
  font-size: 1rem;
}

.menu-main:hover a {
  font-weight: 500;
  font-size: 1rem;
}

.menu-main:hover a + .sub-menu{
  font-weight: 500;
}

.contact-us {
  flex-direction: column;
}

.email, .call-us, .google-direction {
  background-color: var(--gray);
}

.nav-header > div::after {
  content: "SBS PUBLIC SCHOOL";
  font-weight: bold;
  font-size: 1.5rem;
}

}

@media screen and (max-width: 768px) {
  .hamburger-close, .hamburger-open {
  display: block;
}

.nav-header > div::after {
  content: "SBS PUBLIC SCHOOL";
  font-weight: bold;
  font-size: 1.05rem;
}

  .nav-menu {
    flex-direction: column;
    z-index: 1000;
    position: absolute;
    top: 0;
    height: 100vh;
    left: -100%
  }

  .nav-menu.open {
    left: 0;
  }

  .hamburger-open {
    position: fixed;
    top: 40px;
    right: 40px;
    background-color: transparent;
    outline: none;
    border: none;
  }
  .hamburger-close {
    position: absolute;
    top: 40px;
    right: 40px;
    background-color: transparent;
    outline: none;
    border: none;
  }
  .hamburger-close i {
    font-size: 1.5rem;
    color: var(--white);
  }
  .hamburger-open i {
    font-size: 1.5rem;
    color: #8B8B8B;
  }
  .upper-column-footer {
    flex-direction: column;
  }

  .about-us {
    width: 100%;
  }
}


.splide__slide img {
  object-fit: contain;
  height: 80vh;
}

.splide__slide {
  display: flex;
  justify-content: center;
}

.school-container {
  padding: 1rem;
  display: flex;
  justify-content: center;
  padding: 4rem;
}

.about-school-section, .director-message-text {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.about-school-section h3, .director-message-text h3 {
  color: var(--red);
  font-size: 1.5rem;
}

.about-school-section h2, .director-message-text h2 {
  font-size: 2rem;
}

.about-school-section p, .director-message-text p {
  font-size: 1.15rem;
  font-weight: 500;
}

.about-school-icons-container {
  display: flex;
  justify-content: space-evenly;
}

.icons-1, .icons-2 {
  display: flex;
  flex-direction: column;
}

.icons-1 span i, .icons-2 span i {
  color: var(--red);
  font-size: 2rem;
  margin-right: 1rem;
}

.icons-1 span, .icons-2 span {
  font-size: 1.15rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.director-message {
  background: rgb(61,61,61);
  background: linear-gradient(90deg, rgba(61,61,61,1) 0%, rgba(176,176,176,1) 24%, rgba(231,231,231,1) 100%);
  padding: 4rem;
  display: flex;
}

.director-message-text a, .gallery a {
  color: var(--red);
  transition: all ease-in-out 200ms;
}

.director-message-text a:hover, .gallery a:hover {
  font-size: 1.25rem;
  font-weight: 700;
}

.gallery, .facilities {
  padding: 4rem;
}

.gallery h2 {
  display: flex;
  justify-content: space-between;
}

.image-section {
  padding: 2rem 0;
  display: grid;
  place-items: center;
  grid-template-columns: auto auto auto;
}
.iamge-section {
  height: 300px;
  width: 300px;
  object-fit: cover;
}

.gallery-image {
  margin-bottom: 2rem;
  text-align: center;
}

/* .hover-element {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .hover-card {
  position: relative;
  display: inline-block;
  background-color: var(--white);
  padding: 3rem;
  border: var(--red) 0.75rem solid;
  text-align: center;
} */

.hover-close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  cursor: pointer;
}

.hover-title {
  color: var(--red);
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.hover-image-section {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.hover-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.hover-image img {
  height: 200px;
  width: 150px;
}

@media screen and (max-width: 1024px) {
  .splide__slide img {
  object-fit: contain;
  height: 60vh;
}

.about-school-section h3, .director-message-text h3 {
  font-size: 1.15rem;
}

.about-school-section h2, .director-message-text h2 {
  font-size: 1.25rem;
}

.about-school-section p, .director-message-text p {
  font-size: 1rem;
  font-weight: 400;
}

.icons-1 span i, .icons-2 span i {
  font-size: 1.25rem;
}

.icons-1 span, .icons-2 span {
  font-size: 1rem;
  font-weight: 500;
}

.image-section {
  grid-template-columns: auto auto;
}
}

@media screen and (max-width: 768px) {
  .school-container, .director-message {
  align-items: center;
  flex-direction: column;
}
.hover-card {
  padding: 1rem;
}

.hover-image img {
  height: 200px;
  width: 150px;
}

.hover-image h2 {
  font-size: 1rem;
}

.hover-title {
  font-size: 1.25rem;
}

.hover-close-icon {
  font-size: 1.5rem;
}

}

@media screen and (max-width: 665px) {
  .image-section {
  grid-template-columns: auto;
}
}

.mySlides {display: none}
img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev, .next,.text {font-size: 11px}
}
